export const images = [
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/10_Comuna13_Colombia_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/09_ElMedano_Canarias_Europe.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/08_BocaChica_RD_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/07_Tenerife_Canarias_Europe.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/06_SenhorDaPedra_Portugal_Europe.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/05_AbuDhabi_UAE_MiddleEast.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/04_Tulum_Mexico_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/03_MachuPicchu_Peru_Latinoamerica.jpeg',
  `https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/02_Bar'am_Palestine_MiddleEast.jpeg`,
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/01_ValleDelCocora_Colombia_Latinoamerica.jpeg'
];